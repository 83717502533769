.root {
  background: var(--mui-palette-grey-100);
  border-radius: 4px;
  display: flex;
  flex-direction: column;
  height: 72px;
  justify-content: center;
  padding: 16px;
}

.label {
  font-size: 10px;
  font-weight: 700;
  letter-spacing: 0.02em;
  line-height: 1.6;
  text-transform: uppercase;
}

.description {
  color: var(--mui-palette-secondary-main);
  font-size: 12px;
  font-weight: 600;
  line-height: 1.3333333333333333;
  margin-top: 4px;
}
