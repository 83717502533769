.wrapper {
  position: relative;
}

.description {
  position: absolute;
  right: 24px;
  bottom: 24px;
  z-index: 99;
  color: var(--mui-palette-secondary-main);
  cursor: help;
}

.root {
  background: var(--mui-palette-common-white);
  border-radius: 4px;
  cursor: pointer;
  height: 256px;
  padding: 24px;
  position: relative;
  transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  box-sizing: border-box;
}
.root * {
  box-sizing: border-box;
}

.disabled {
  cursor: not-allowed;
  opacity: 0.5;
}

.body {
  margin-top: 40px;
}

.head {
  display: flex;
}

.status {
  flex: 1 0 auto;
  margin-left: 16px;
}

.icon {
  flex: 0 0 auto;
}

.startDate {
  font-size: 12px;
  line-height: 1;
  font-weight: 700;
  letter-spacing: 0.02em;
  text-transform: uppercase;
  color: var(--mui-palette-text-disabled);
  margin-bottom: 4px;
}

.label {
  font-size: 14px;
  line-height: 1.6;
  font-weight: 700;
  letter-spacing: 0.02em;
  text-transform: uppercase;
}
