.icon {
  color: var(--mui-palette-common-white);
  height: 1em;
  width: 1em;
}

.phonesAndLaptops {
  fill: var(--mui-palette-common-white);
}

.phonesAndLaptops path {
  transform: scale(1.5);
}
